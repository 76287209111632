/* app global css */
@font-face {
  font-family: 'Helvetica';
  src: local('Merienda'), url(../assets/font/Helvetica.ttf) format('truetype');
}

@font-face {
  font-family: 'Helvetica-light';
  src: local('Merienda'),
    url(../assets/font/helvetica-light-587ebe5a59211.ttf) format('truetype');
}

:root {
  --color-primary: #e8aa10;
  --background: #c7c1c1;
  --gray: #999999;
  --black: #333333;
  --white: #ffffff;
}

body {
  font-family: Helvetica;
  color: #333333;
}

.btn {
  height: 52px;
  width: 244px;
  background-color: #e8aa10;
  font-size: 22px;
  font-weight: bold;
}

/* text style */
.text-45 {
  font-size: 40px;
}

.text-30 {
  font-size: 30px;
}

.text-25 {
  font-size: 23px;
}

.text-24 {
  font-size: 22px;
}

.text-18 {
  font-size: 17px;
}

.text-16 {
  font-size: 16px;
}

.text-14 {
  font-size: 14px;
}

.text-12 {
  font-size: 12px;
}

.light-font {
  font-family: Helvetica-light;
}

.italic-font {
  font-style: italic;
}

.bold-weight-font {
  font-weight: bold;
}

.white-font {
  color: var(--white);
}

.black-font {
  color: var(--black) !important;
}

.primary-font {
  color: var(--color-primary);
}

.bg-primary {
  background-color: var(--color-primary) !important;
}

.spacing-15 {
  letter-spacing: 0.15em;
}

.text-align-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

/* end text style */
@media screen and (max-width: 768px) {
  .btn {
    font-size: 17px;
  }
}

/* Dialog Design https://xd.adobe.com/view/f490bea9-6112-4553-a500-4f1c88146b1b-a1c3/screen/3808d8e8-90e0-4007-bf5a-b3ff3d5181e8/specs/ */

.q-dialog__inner--minimized > div {
  max-height: calc(100dvh - 48px);
}
.q-dialog__inner > .q-card {
  border-radius: 12px;
  min-width: 300px;
  min-height: 165px;
  width: 300px;
}
.q-dialog__title {
  padding: 27px 20px 9px 20px;
  text-align: center;
  font-weight: bold;
  font-size: 20px;
  line-height: 28px;
  color: #1c1c1c;
}
.q-dialog__message {
  padding: 0 20px 27px 20px;
  text-align: center;
  font-size: 16px;
  line-height: 22px;
  color: #666666;
}
.q-dialog .q-card__actions--horiz {
  box-shadow: inset #d9d9d9 0 1px 0 0 !important;
  padding: 0;
  height: 52px;
  align-items: stretch;
}
.q-dialog .q-card__actions--horiz > .q-btn-item {
  padding: 0;
  flex: 1 1 0;
  font-size: 16px;
  border-radius: 0;
  color: #666666 !important;
  text-transform: none;
}
.q-dialog .q-card__actions--horiz > .q-btn-item .q-focus-helper {
  background: none !important;
}
.q-dialog .q-card__actions--horiz > .q-btn-item.q-focusable:focus {
  font-weight: bold;
  color: #fcb400 !important;
}
.q-dialog .q-card__actions--horiz > .q-btn-item + .q-btn-item {
  margin-left: 0;
  box-shadow: inset #d9d9d9 1px 0 0 0;
}
.q-dialog.large .q-dialog__inner > .q-card {
  width: 100%;
}
@media screen and (min-width: 640px) {
  .q-dialog.large .q-dialog__inner > .q-card {
    max-width: 800px;
    max-height: 600px;
  }
  .q-dialog.large .q-dialog__title {
    padding: 37px 52px 9px 52px;
  }
  .q-dialog.large .q-dialog__message {
    padding: 0 52px 37px 52px;
  }
}
